import { useMemo } from "react";
import {
  EpisodeOfCare,
  EpisodeOfCareFilterSet,
  EpisodeOfCareSortField,
  EpisodeOfCareStatus,
  EpisodeOfCaresClient,
  IItemView,
  IStore,
  LocalDate,
  SortField
} from "@remhealth/apollo";
import { EmptyView, isDateRangeEffective, usePracticePreferences } from "@remhealth/host";
import { useStore } from "./useStore";

export interface EpisodeOfCareFilterOptions {
  patientIds: string[];
  includeCancelled?: boolean;
  effectiveOn?: LocalDate;
  allowPastDischarged?: boolean;
}

export function useEpisodeOfCaresView(sort: SortField<EpisodeOfCareSortField>, options: EpisodeOfCareFilterOptions): IItemView<EpisodeOfCare> {
  const store = useStore();
  const practicePreferences = usePracticePreferences();
  const allowedDaysPastDischargedEpisode = practicePreferences.allowedDaysPastDischargedEpisode ?? 1;

  return useMemo(() => getEpisodeOfCaresView(store.episodeOfCares, sort, options, allowedDaysPastDischargedEpisode), [JSON.stringify([options, sort, allowedDaysPastDischargedEpisode])]);
}

export function getEpisodeOfCaresView(store: IStore<EpisodeOfCaresClient>, sort: SortField<EpisodeOfCareSortField>, options: EpisodeOfCareFilterOptions, allowedDaysPastDischargedEpisode: number) {
  if (options.patientIds.length === 0) {
    return new EmptyView<EpisodeOfCare>();
  }

  return store.view({
    filters: {
      online: createEpisodeOfCareFilters(options, allowedDaysPastDischargedEpisode),
      offline: s => offlinefilter(s, options, allowedDaysPastDischargedEpisode),
    },
    orderBy: {
      online: sort,
      offline: sortOffline(sort),
    },
    feedOptions: options.patientIds.length === 1 ? { partition: options.patientIds[0] } : { crossPartition: true },
  });
}

export function createEpisodeOfCareFilters(options: EpisodeOfCareFilterOptions, allowedDaysPastDischargedEpisode: number): EpisodeOfCareFilterSet[] {
  const patientFilter: EpisodeOfCareFilterSet = {
    patient: {
      in: options.patientIds,
    },
  };

  const cancelledFilter: EpisodeOfCareFilterSet = options.includeCancelled ? {} : {
    status: {
      notIn: [EpisodeOfCareStatus.Cancelled],
    },
  };

  let periodFilter: EpisodeOfCareFilterSet = !options.effectiveOn ? {} : {
    period: {
      wraps: options.effectiveOn,
    },
  };

  if (options.effectiveOn && options.allowPastDischarged && allowedDaysPastDischargedEpisode) {
    const datetime = LocalDate.fromDateTime(LocalDate.toDateTime(options.effectiveOn).minus({ days: allowedDaysPastDischargedEpisode }));
    periodFilter = {
      period: {
        overlaps: {
          start: datetime,
          end: options.effectiveOn,
        },
      },
    }
  }

  return [
    {
      ...patientFilter,
      ...cancelledFilter,
      ...periodFilter,
    },
  ];
}

function offlinefilter(episodeOfCare: EpisodeOfCare, options: EpisodeOfCareFilterOptions, allowedDaysPastDischargedEpisode: number) {
  if (!options.patientIds.includes(episodeOfCare.patient.id)) {
    return false;
  }

  if (!options.includeCancelled && episodeOfCare.status === EpisodeOfCareStatus.Cancelled) {
    return false;
  }

  if (options.effectiveOn) {
    if (options.allowPastDischarged && allowedDaysPastDischargedEpisode > 0) {
      const datetime = LocalDate.fromDateTime(LocalDate.toDateTime(options.effectiveOn).minus({ days: allowedDaysPastDischargedEpisode }));
      if (!isDateRangeEffective(episodeOfCare.period, LocalDate.toDate(datetime))) {
        return false;
      }
    } else if (!isDateRangeEffective(episodeOfCare.period, LocalDate.toDate(options.effectiveOn))) {
      return false;
    }
  }

  return true;
}

function sortOffline(sort: SortField<EpisodeOfCareSortField>) {
  const dir = sort.direction === "Ascending" ? 1 : -1;
  return (a: EpisodeOfCare, b: EpisodeOfCare) => {
    switch (sort.field) {
      case "Period":
      default:
        return dir * (a.period.start ?? "").localeCompare(b.period.start ?? "");
    }
  };
}
