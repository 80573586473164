import React from "react";
import { ItemListRendererProps as CoreItemListRendererProps } from "@blueprintjs/select";
import { MenuDivider } from "@blueprintjs/core";
import { ZoomOut } from "@remhealth/icons";
import type { PagingResult } from "~/hooks/usePageLoader";
import { MenuItem } from "../components/menuItem";

export const DefaultSuggestInitialContent = <MenuItem readonly icon="search" text="Type to search..." />;
export const DefaultNoResults = <MenuItem readonly icon={<ZoomOut />} text="No results found" />;
export const DefaultMoreResultsAvailable = <MenuItem readonly icon="search" text="More results available, refine the search." />;

export type ItemListRenderer<T> = (itemListProps: ItemListRendererProps<T>) => JSX.Element | null;

export interface ItemListRendererProps<T> extends Omit<CoreItemListRendererProps<T>, "renderItem"> {
  noResults?: React.ReactNode;
  initialContent?: React.ReactNode | null;
  renderItems: (noResults?: React.ReactNode, initialContent?: React.ReactNode | null) => React.ReactNode;
}

export interface ListGrouping<TItem, TListProps extends ItemListRendererProps<TItem> = ItemListRendererProps<TItem>> {
  key: string;
  items: TItem[];
  renderHeader?: (listProps: TListProps) => JSX.Element;
  renderMenu?: (listProps: TListProps) => JSX.Element;
}

export type ListGroupRenderer<TItem, TListProps extends ItemListRendererProps<TItem>> = (items: TItem[]) => ListGrouping<TItem, TListProps>[];

export function extendItemListRendererProps<TItem, TListProps extends ItemListRendererProps<TItem>>(
  props: Omit<CoreItemListRendererProps<TItem> & TListProps, "renderItems">,
  groupRenderer: ListGroupRenderer<TItem, TListProps> | undefined,
  noResults?: React.ReactNode,
  initialContent?: React.ReactNode | null
): TListProps {
  const extendedProps = { ...props, noResults, initialContent, renderItems } as TListProps;
  return extendedProps;

  function renderItems(renderNoResults: React.ReactNode = noResults, renderInitialContent: React.ReactNode | null = initialContent): React.ReactNode {
    if (props.query.length === 0 && renderInitialContent) {
      return renderInitialContent;
    }

    let items: JSX.Element[];

    if (groupRenderer) {
      let index = 0;
      items = groupRenderer(props.filteredItems).map(group => {
        if (group.renderHeader) {
          return (
            <React.Fragment key={group.key}>
              {group.renderHeader(extendedProps)}
              {group.items.map((item) => props.renderItem(item, index++))}
            </React.Fragment>
          );
        }

        if (group.renderMenu) {
          const children = group.items.map((item) => props.renderItem(item, index++)).flatMap(item => item != null ? item : []);
          return React.cloneElement(group.renderMenu(extendedProps), {}, children);
        }

        return (
          <React.Fragment key={group.key}>
            <MenuDivider title={group.key} />
            {group.items.map((item) => props.renderItem(item, index++))}
          </React.Fragment>
        );
      }).flatMap(item => item != null ? item : []);
    } else {
      items = props.filteredItems.map(props.renderItem).flatMap(item => item != null ? item : []);
    }

    return items.length > 0 ? items : renderNoResults;
  }
}

export interface NoResultsProps {
  label: string;
}

export const NoResults = (props: NoResultsProps) => <MenuItem readonly icon={<ZoomOut />} text={`No ${props.label} found`} />;

export function escapeRegExpChars(text: string) {
  return text.replace(/([!$()*+./:=?[\\\]^{|}])/g, "\\$1");
}

/**
 * Helper function to highlight words in a given text string.
 */
export type HighlightEnclosingTag = "strong" | "mark";
export function highlightText(text: string, query: string, ignoreCase = true, containsMatch = false, tag: HighlightEnclosingTag = "strong"): React.ReactNode {
  if (!text) {
    return text;
  }

  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter(word => word.length > 0)
    .map(escapeRegExpChars);

  if (words.length === 0) {
    return [text];
  }

  const regexp = new RegExp(words.join("|"), ignoreCase ? "gi" : "g");
  const tokens: React.ReactNode[] = [];

  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }

    const length = match[0].length;
    const start = regexp.lastIndex - length;

    if (!containsMatch && start > 0 && text.slice(start - 1, start) !== " ") {
      continue;
    }

    const before = text.slice(lastIndex, start);

    if (before.length > 0) {
      tokens.push(before);
    }

    lastIndex = regexp.lastIndex;
    switch (tag) {
      case "mark":
        tokens.push(<mark key={lastIndex}>{match[0]}</mark>);
        break;
      case "strong":
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
  }

  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }

  return <>{tokens}</>;
}

export type AsyncQuery<T> = (query: string, abort: AbortSignal) => Promise<PagingResult<T>>;

export function startsWithAllWords(value: string | undefined, query: string, caseInsensitive = true): boolean {
  const valueWords = splitWords(caseInsensitive ? value?.toLowerCase() : value);
  const queryWords = splitWords(caseInsensitive ? query.toLowerCase() : query);
  return queryWords.every(queryWord => {
    return valueWords.some(foundWord => foundWord.startsWith(queryWord));
  });
}

export function startsWithAnyWords(value: string | undefined, query: string, caseInsensitive = true): boolean {
  const valueWords = splitWords(caseInsensitive ? value?.toLowerCase() : value);
  const queryWords = splitWords(caseInsensitive ? query.toLowerCase() : query);
  return queryWords.some(queryWord => {
    return valueWords.some(foundWord => foundWord.startsWith(queryWord));
  });
}

function splitWords(value: string | undefined): string[] {
  if (!value?.trim()) {
    return [];
  }

  return value
    // Common mid-word punctuation
    // e.g. "justin's" "mid-word" "justin@remarkablehealth.com" "512-555-0202"
    .replace("'", "")
    .replace("-", "")
    .replace("+", "")
    .replace("@", "")
    .replace(".", "")
    // Common word wrappers
    .replace('"', " ")
    .replace("(", " ")
    .replace(")", " ")
    .replace("[", " ")
    .replace("]", " ")
    .replace("{", " ")
    .replace("}", " ")
    .replace("`", " ")
    .replace("*", " ")
    .replace("%", " ")
    .replace("_", " ")
    .replace("~", " ")
    .replace("<", " ")
    .replace(">", " ")
    // Common separators
    .replace("\r", " ")
    .replace("\n", " ")
    .replace("\t", " ")
    .replace("!", " ")
    .replace("#", " ")
    .replace("$", " ")
    .replace("^", " ")
    .replace("&", " ")
    .replace(":", " ")
    .replace(";", " ")
    .replace("?", " ")
    .replace(",", " ")
    .replace("=", " ")
    .replace("/", " ")
    .replace("\\", " ")
    .replace("|", " ")
    .split(" ")
    .filter(word => !!word.trim());
}
