import { memo } from "react";
import styled, { css } from "styled-components";
import { Svg } from "@remhealth/ui";
import { Practice } from "@remhealth/apollo";
import { isReleased, useUserSession } from "@remhealth/host";
import { BellsAvatars } from "../assets";

interface ContainerProps {
  $size: number;
  $round: boolean;
  $interactive: boolean;
}

const Container = styled.div<ContainerProps>`
  border-radius: ${props => props.$round ? "50%" : "0"};
  overflow: hidden;

  ${props => props.$interactive && css`
    cursor: pointer;
  `};

  ${props => props.$round && css`
    width: ${props.$size}px;
    height: ${props.$size}px;
    > svg {
      width: 100%;
      height: 145%; // Only show her head in the circle
    }
  `};

  ${props => !props.$round && css`
    max-width: ${props.$size}px;
    max-height: ${props.$size}px;

    > svg {
      width: 100%;
      height: 100%;
      max-width: ${props.$size}px;
      max-height: ${props.$size}px;
    }
  `};
`;

export interface BellsAvatarProps {
  size?: number;
  round?: boolean;
  onClick?: () => void;
}

export const BellsAvatar = memo((props: BellsAvatarProps) => {
  const { size = 40, round = false, onClick } = props;

  const session = useUserSession();

  return (
    <Container
      $interactive={!!onClick}
      $round={round}
      $size={size}
      className="bells-avatar"
      onClick={onClick}
    >
      <Svg src={getBellsAvatar(session.practice)} />
    </Container>
  );
});

/** Since the avatar used changes often, here we maintain which one is used in one place. */
export function getBellsAvatar(practice?: Practice) {
  if (practice) {
    if (isReleased(practice, "SuperbowlTeal")) {
      return BellsAvatars.SuperBowlTeal;
    } else if (isReleased(practice, "SuperbowlRed")) {
      return BellsAvatars.SuperBowlRed;
    }
  }
  return BellsAvatars.SuperBowlDualRedTeal;
}
