import { useEffect } from "react";
import { EpisodeOfCareFilterOptions, useEpisodeOfCaresView, useFeed } from "@remhealth/core";
import { useProductFlag, useReleaseCheck } from "@remhealth/host";
import { IFeed, useAbort } from "@remhealth/ui";
import { EpisodeOfCare } from "@remhealth/apollo";
import { OpenPatient } from "~/contexts";

export interface EpisodeOfCaresHookOptions extends Omit<EpisodeOfCareFilterOptions, "patientIds"> {

}

export function useEpisodeOfCares(patient: OpenPatient, options: EpisodeOfCaresHookOptions): IFeed<EpisodeOfCare> {
  const abort = useAbort();
  const hasAllowNotesForDischargedEpisodes = useProductFlag("AllowNotesForDischargedEpisodes");
  const isOrgPreferencePostDischargeReleased = useReleaseCheck("OrgPreferencePostDischarge");

  const view = useEpisodeOfCaresView({ direction: "Descending", field: "Period" }, {
    patientIds: [patient.patient.id],
    ...options,
    allowPastDischarged: isOrgPreferencePostDischargeReleased && hasAllowNotesForDischargedEpisodes && options.allowPastDischarged,
  });

  const feed = useFeed(view, {
    reload: false,
  });

  useEffect(() => {
    loadAll();
    return patient.onPullComplete(view.key, handlePullComplete);
  }, [view.key]);

  return feed;

  async function handlePullComplete() {
    feed.reset(true);
    loadAll();
  }

  async function loadAll() {
    while (feed.canLoadMore && !abort.signal.aborted) {
      await feed.loadMore(100, abort.signal);
    }
  }
}
