import { DateTime } from "luxon";
import {
  Approximate,
  ApproximateRange,
  CarePlanActivity,
  Duration,
  Encounter,
  EncounterStatus,
  GoalElementType,
  Note,
  NoteDefinition,
  type NoteSection,
  NoteSectionFormat,
  Participant,
  QuestionnaireAnswer,
  QuestionnaireElement,
  VisitStatus
} from "@remhealth/apollo";
import { getVisitStatus } from "~/utils";
import { QuestionnaireContext } from "~/questionnaire/contexts";
import { getQuestionDependencies } from "~/questionnaire/dependencies";
import { answerValueHasValue } from "~/questionnaire/utils";
import { hasLinkedSectionForm } from "~/questionnaire/flavors";
import { validateCarePlanActivities } from "~/goals/utils";
import { isGoalElementAtLeastLevel } from "../utils";

const defaultPatientViewableSection = new Set<NoteSectionFormat>([
  NoteSectionFormat.Diagnosis,
  NoteSectionFormat.Problems,
  NoteSectionFormat.SessionTime,
  NoteSectionFormat.AddOn,
  NoteSectionFormat.ClinicalQualityIndicator,
]);

export interface NoteSectionValidationContext extends QuestionnaireContext {
  note: Note;
  encounter: Encounter;
  definition: NoteDefinition | undefined;
  patientViewableMode: boolean;
  checkNoteTypeGoalsMinimumLevel: boolean;
  allowZeroDurationNonShowNotes: boolean;
}

export function isNoteSectionValid(section: NoteSection, context: NoteSectionValidationContext): boolean {
  const {
    note,
    encounter,
    definition,
    patientViewableMode,
    checkNoteTypeGoalsMinimumLevel,
    allowZeroDurationNonShowNotes,
  } = context;

  const required = defaultPatientViewableSection.has(section.format)
    ? section.required
    : patientViewableMode
      // Sections hidden while in patient view mode aren't required
      ? section.includeInPatientView && section.required
      : section.required;

  switch (section.format) {
    case NoteSectionFormat.Form: {
      const questions = section.form?.resource?.elements;

      const formValid = isSectionFormValid(questions, section.formAnswers, context, patientViewableMode);
      return formValid;
    }

    case NoteSectionFormat.Text: {
      if (required) {
        const noteSection = note.sections.find(n => n.format === section.format && n.name.localeCompare(section.name) === 0);
        if ((noteSection?.text?.plainText?.trim().length ?? 0) === 0) {
          return false;
        }
      }
      return true;
    }

    case NoteSectionFormat.Diagnosis: {
      if (required && encounter.diagnoses.length === 0) {
        return false;
      }
      return true;
    }

    case NoteSectionFormat.GoalsObjectives: {
      if (required && encounter.carePlanActivities.length === 0) {
        return false;
      }

      if (checkNoteTypeGoalsMinimumLevel && encounter.carePlanActivities.length > 0) {
        if (!verifyNoteTypeGoalsMinimumLevel(encounter.carePlanActivities, section.requiredGoalElementType)) {
          return false;
        }
      }

      return validateCarePlanActivities(encounter.carePlanActivities, definition?.leafLevelGoalComments ?? false, section.requireComments);
    }

    case NoteSectionFormat.Problems: {
      if (required && encounter.problems.length === 0) {
        return false;
      }
      return true;
    }

    case NoteSectionFormat.SessionTime: {
      const canHaveZeroSessionTime = !required || !durationRequired(encounter, allowZeroDurationNonShowNotes);
      const canHaveFutureSessionTime = encounter.status === EncounterStatus.Cancelled;
      if (!areAllParticipantsValid(note, canHaveZeroSessionTime, canHaveFutureSessionTime, definition?.earlySignatureAllowedMinutes ?? 0)) {
        return false;
      }

      return true;
    }

    case NoteSectionFormat.CtoneCustomDropdown: {
      if (required && !note.customOption) {
        return false;
      }

      return true;
    }

    case NoteSectionFormat.EvidenceBasedPractices: {
      if (required && note.evidenceBasedPracticesCodings.length === 0) {
        return false;
      }
      return true;
    }

    case NoteSectionFormat.AddOn: {
      if (required && note.additionalServices.length === 0) {
        return false;
      }
      if (note.additionalServices.length !== 0) {
        return note.additionalServices.every(service => {
          if (service.duration !== undefined) {
            return Duration.toLuxon(service.duration).as("minutes") > 0
              && Duration.toLuxon(service.duration).as("minutes") < 999;
          }
          if (service.extensions?.some(b => b.name === "requiresDuration" && b.value === "true")) {
            return service.duration !== undefined;
          }
          return true;
        });
      }
      return true;
    }

    default:
      return true;
  }
}

function isSectionFormValid(questions: QuestionnaireElement[] | undefined, formAnswers: QuestionnaireAnswer[], questionnaireContext: QuestionnaireContext, patientViewableMode = false, depth = 0): boolean {
  if (!questions) {
    return true;
  }

  return getQuestionDependencies(questions, questionnaireContext)
    .getVisible(formAnswers)
    .every(question => {
      if (!question.required) {
        return true;
      }

      if (patientViewableMode && !question.includeInPatientView) {
        return true;
      }

      // Signatures are validated separately
      if (question.type === "Signature") {
        return true;
      }

      const answer = formAnswers.find(a => a.linkId === question.linkId);

      if (depth === 0 && hasLinkedSectionForm(question)) {
        const nestedAnswers = answer?.values.flatMap(value => value.valueGrid).flatMap(valueGrid => valueGrid?.answers ?? []) ?? [];
        return isSectionFormValid(question.form.resource?.elements, nestedAnswers, questionnaireContext, patientViewableMode, depth + 1);
      }

      if (!answer?.values.some(answerValueHasValue)) {
        return false;
      }

      if (question.type === "Choice" || question.type === "OpenChoice") {
        if (question.answerMinimum !== undefined && answer.values.length < question.answerMinimum) {
          return false;
        }

        if (question.answerMaximum !== undefined) {
          const answerMaximum = question.answerMaximum + (question.type === "OpenChoice" ? 1 : 0);
          if (answer.values.length > answerMaximum) {
            return false;
          }
        }
      }

      return true;
    });
}

export function durationRequired(encounter: Encounter, allowZeroDurationNonShowNotes: boolean) {
  if (getVisitStatus(encounter.status) === VisitStatus.Show) {
    return true;
  }

  return !allowZeroDurationNonShowNotes;
}

export function isFuturePeriod(period: ApproximateRange, earlySignatureAllowedMinutes: number): boolean {
  const now = DateTime.now();
  if (period.start && now < Approximate.toDateTime(period.start)) {
    return true;
  }
  if (period.end && now < Approximate.toDateTime(period.end).minus({ minutes: earlySignatureAllowedMinutes })) {
    return true;
  }
  return false;
}

function areAllParticipantsValid(note: Note, canHaveZeroSessionTime: boolean, canHaveFutureSessionTime: boolean, earlySignatureAllowedMinutes: number) {
  if (note.participants.length === 0) {
    return false;
  }

  return note.participants
    .filter(p => p.role === "PrimaryPerformer")
    .every(p => isParticipantValid(p, canHaveZeroSessionTime, canHaveFutureSessionTime, earlySignatureAllowedMinutes));
}

function isParticipantValid(participant: Participant, canHaveZeroSessionTime: boolean, canHaveFutureSessionTime: boolean, earlySignatureAllowedMinutes: number): boolean {
  if (!participant.period) {
    return canHaveZeroSessionTime;
  }

  return isSessionTimeValid(participant.period, canHaveZeroSessionTime, canHaveFutureSessionTime, earlySignatureAllowedMinutes);
}

function isSessionTimeValid(period: ApproximateRange, canHaveZeroSessionTime: boolean, allowFuture: boolean, earlySignatureAllowedMinutes: number): boolean {
  if (!period.start || !period.end) {
    return canHaveZeroSessionTime;
  }

  if (!isValidPeriod(period, canHaveZeroSessionTime)) {
    return false;
  }

  if (!allowFuture && isFuturePeriod(period, earlySignatureAllowedMinutes)) {
    return false;
  }

  return true;
}

function isValidPeriod(period: ApproximateRange, canHaveSameTime: boolean): boolean {
  if (!period.start || !period.end) {
    return false;
  }

  const start = Approximate.toDateTime(period.start);
  const end = Approximate.toDateTime(period.end);

  if (canHaveSameTime) {
    if (start > end) {
      return false;
    }
  } else if (start >= end) {
    return false;
  }

  return true;
}

function verifyNoteTypeGoalsMinimumLevel(carePlanActivities: CarePlanActivity[], minimumLevel: GoalElementType) {
  return carePlanActivities.every(cpa => cpa.activity.every(a => isGoalElementAtLeastLevel(a, minimumLevel, GoalElementType.Goal)));
}
