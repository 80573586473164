import { useMemo } from "react";
import { Markup } from "@remhealth/core";
import { RichText } from "@remhealth/apollo";
import { Text } from "~/text";
import { Container, NoData, SectionHeader } from "./common.styles";

export interface TextSectionContentProps {
  name: string;
  text: RichText | undefined;
  highlight?: string;
  includeSectionHeader?: boolean;
}

export function TextSectionContent(props: TextSectionContentProps) {
  const { name, text, highlight, includeSectionHeader = true } = props;

  const highlights = useMemo(() => highlight?.split(" "), [highlight]);

  return (
    <Container>
      {includeSectionHeader && <SectionHeader>{name}</SectionHeader>}
      {!text?.plainText?.trim()
        ? <NoData>{Text.NoData}</NoData>
        : (
          <div>
            <Markup highlights={highlights} source={text.value} />
          </div>
        )}
    </Container>
  );
}
