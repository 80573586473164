import styled from "styled-components";
import { TextArea } from "@remhealth/ui";

export const CancelReasonTextArea = styled(TextArea)`
  min-height: 100px;
  resize: none;
  width: 100%;
`;

export const CancelStatusWrapper = styled.div`
  margin: 0 0 20px 0px;
`;

export const Contents = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;
