import { uniqBy } from "lodash-es";
import { DateTime } from "luxon";
import { isSameDay } from "@remhealth/ui";
import { type PracticePreferencesData, areCodingsSame, isDateRangeEffective } from "@remhealth/host";
import {
  Approximate,
  type ApproximateRange,
  Duration,
  Encounter,
  EpisodeOfCare,
  GoalElementType,
  GroupNoteDefinition,
  Instant,
  Participant,
  ParticipantRole,
  Program,
  ProgramEnrollment,
  Reference,
  Signature,
  VisitStatus,
  knownCodings
} from "@remhealth/apollo";
import { getDeadline } from "~/utils";
import { CompletedSignature, DraftSignature, Enrollment, GroupNoteTemplateType, Session, createEnrollmentKey } from "./types";

export function spansMidnight(participant: Participant) {
  return participant?.period?.start && participant?.period?.end
    && !isSameDay(Approximate.toDateTime(participant.period.end), Approximate.toDateTime(participant.period.start));
}

export function getLengthOfSessionMinutes(sessionList: Session[]) {
  return sessionList.map(getSessionMinutes).reduce((a, b) => a + b, 0);
}

export function getSessionMinutes(session: Session) {
  return session.start && session.end ? Math.max(0, Math.round(DateTime.fromJSDate(session.end).diff(DateTime.fromJSDate(session.start)).as("minutes"))) : 0;
}

export function getSessionToSign(signTime: DateTime, sessionTime: DateTime | undefined) {
  if (sessionTime) {
    return Duration.humanize(signTime.diff(sessionTime).shiftTo("hours", "minutes", "seconds"), { maxUnits: 1, minUnit: "seconds" });
  }
  return undefined;
}

export function applyParticipantPeriod(participants: Participant[], period: ApproximateRange): Participant[] {
  const results: Participant[] = [];

  for (const participant of participants) {
    if (participant.role === ParticipantRole.PrimaryPerformer || participant.role === ParticipantRole.SecondaryPerformer) {
      results.push({ ...participant, period });
    } else {
      results.push({ ...participant, period: undefined });
    }
  }

  return results;
}

/**
 * Will create program enrollment for a given program, with empty data set for effective & diagnoses.
 * used to populate Programs select using programs fetched from ehr
 */
export const createProgramEnrollment = (program: Program | Reference<Program>): ProgramEnrollment => ({
  program: {
    id: program.id,
    display: program.display,
    resourceType: program.resourceType,
    resource: isResource(program) ? program : undefined,
  },
  diagnoses: [],
});

function isResource(item: any): item is Program {
  return typeof item.meta !== "undefined";
}

export function getPrograms(enrollment: Enrollment | undefined, visitTime?: Date) {
  return enrollment?.episodeOfCare.resource?.programs.filter((program: ProgramEnrollment) => isDateRangeEffective(program.effective, visitTime)) ?? [];
}

export function episodeOfCaresToEnrollments(episodeOfCares: EpisodeOfCare[], visitTime: Date | undefined, excludeProgram?: boolean): Enrollment[] {
  return uniqBy(episodeOfCares.flatMap(eoc => episodeOfCareToEnrollments(eoc, visitTime, excludeProgram)), e => e.id);
}

export function episodeOfCareToEnrollments(eoc: EpisodeOfCare, visitTime: Date | undefined, excludeProgram?: boolean): Enrollment[] {
  const episodeOfCareRef: Reference<EpisodeOfCare> = {
    id: eoc.id,
    resourceType: eoc.resourceType,
    display: eoc.display,
    partition: eoc.partition,
    resource: eoc,
  };
  if (eoc.programs.length === 0 || excludeProgram) {
    return [
      {
        id: createEnrollmentKey(eoc.id, undefined),
        episodeOfCare: episodeOfCareRef,
      },
    ];
  }
  const enrollments: Enrollment[] = eoc.programs
    .filter((program) => isDateRangeEffective(program.effective, visitTime))
    .map(p => {
      return {
        id: createEnrollmentKey(eoc.id, p.program.id),
        episodeOfCare: episodeOfCareRef,
        programEnrollment: p,
      };
    });

  if (enrollments.length === 0) {
    return [
      {
        id: createEnrollmentKey(eoc.id, undefined),
        episodeOfCare: episodeOfCareRef,
      },
    ];
  }
  return uniqBy(enrollments, e => e.id);
}

export function getDocumentationDeadline(visitTime: Approximate, program: Program | undefined, practicePreferences: PracticePreferencesData): Instant {
  const sessionToSignIncludesWeekends = program?.noteComplianceOverrides?.sessionToSignIncludesWeekends ?? practicePreferences.sessionToSignIncludesWeekends;
  const sessionToSignThreshold = program?.noteComplianceOverrides?.sessionToSignThresholdHours ?? practicePreferences.noteSignWarningTimeout;
  const thresholdDuration = Duration.fromHours(sessionToSignThreshold);
  return getDeadline(Instant.fromDate(Approximate.toDate(visitTime)), thresholdDuration, sessionToSignIncludesWeekends);
}

export function isPatientSignature(signature: Signature) {
  return signature.signer?.resourceType === "Patient" || !!signature.role?.codings.some(c => areCodingsSame(c, knownCodings.relationship.patient));
}

export function isGuardianSignature(signature: Signature) {
  return signature.signer?.resourceType === "RelatedPerson" || !!signature.role?.codings.some(c => areCodingsSame(c, knownCodings.relationship.guardian));
}

export function isCompleteSignature(signature: DraftSignature | CompletedSignature): signature is CompletedSignature {
  return "signed" in signature && !!signature.signed;
}

export function hasPatientSigned(signatures: (DraftSignature | CompletedSignature)[]) {
  return signatures.some(isCompleteSignature);
}

interface GoalElementNode {
  elementType?: GoalElementType;
  children: GoalElementNode[];
}

/**
 * Minimum level - Requirement
 * Goal - Any level meets requirement
 * Objective - Objective or Intervention must be selected
 * Intervention - Intervention must be selected
 */
export function isGoalElementAtLeastLevel(goalElement: GoalElementNode, minimumLevel: GoalElementType, assumedLevel: GoalElementType): boolean {
  if (minimumLevel === GoalElementType.Goal) {
    return true;
  }

  const actualLevel = goalElement.elementType ?? assumedLevel;

  if (actualLevel === minimumLevel) {
    return true;
  }

  if (minimumLevel === GoalElementType.Objective && actualLevel === GoalElementType.Intervention) {
    return true;
  }

  const nextAssumedLevel = actualLevel === GoalElementType.Goal ? GoalElementType.Objective : GoalElementType.Intervention;
  return goalElement.children.length > 0 && goalElement.children.every(c => isGoalElementAtLeastLevel(c, minimumLevel, nextAssumedLevel));
}

export function getEnrollmentAndProgramValue(encounter: Encounter) {
  if (encounter.program?.display) {
    if (encounter.episodeOfCare?.display) {
      return `${encounter.episodeOfCare?.display} (${encounter.program.display})`;
    }
    return encounter.program.display;
  }

  return encounter.episodeOfCare?.display;
}

export const noteDefinitionMap: Record<VisitStatus, Extract<keyof GroupNoteDefinition, "show" | "noShow" | "cancelled">> = {
  [VisitStatus.Show]: "show",
  [VisitStatus.NoShow]: "noShow",
  [VisitStatus.Cancelled]: "cancelled",
};

export const noteTemplateMap: Record<VisitStatus, GroupNoteTemplateType> = {
  [VisitStatus.Show]: "showTemplate",
  [VisitStatus.NoShow]: "noShowTemplate",
  [VisitStatus.Cancelled]: "cancelledTemplate",
};
