import { isEmpty } from "lodash-es";
import { Appointment, Coding, type HealthcareService, LocalDate, ZonedDateTime } from "@remhealth/apollo";
import { areCodingsSame, useProductFlag, useReleaseCheck } from "@remhealth/host";
import { Form, FormContent, useLabeling, useStore } from "@remhealth/core";
import { Button, Checkbox, Classes, Dialog, FormGroup, Intent, ListOption, SelectInput } from "@remhealth/ui";

import { Text } from "~/text";
import { ServiceTypeSelect } from "~/notes/selects";
import { getAppointmentStatus, useAppointmentCancelStatuses, useAppointmentStatus } from "./utils";
import { CancelReasonTextArea, CancelStatusWrapper, Contents } from "./cancelAppointmentDialog.styles";

export interface CancelAppointmentDialogProps {
  appointment: Appointment;
  isOpen: boolean;
  onClose: () => void;
}

interface CancelAppointmentForm {
  cancelReason?: string;
  status?: Coding;
  missedVisit?: boolean;
  missedVisitServiceCode?: HealthcareService;
}

export function CancelAppointmentDialog(props: CancelAppointmentDialogProps) {
  const { isOpen, appointment, onClose } = props;

  const store = useStore();
  const cancelStatuses = useAppointmentCancelStatuses();
  const { isCancelledStatus } = useAppointmentStatus();

  const missedVisit = useProductFlag("MissedVisit");

  const isMyAvatarMissedVisitReleased = useReleaseCheck("myAvatarMissedVisit");

  const canSubmit = !isCancelledStatus(appointment.status);
  const labels = useLabeling();
  const patient = appointment.attendees.find(a => a.subject.resourceType === "Patient");

  return (
    <Dialog
      enforceFocus={false}
      isOpen={isOpen}
      title={Text.CancelAppointment}
      onClose={onClose}
    >
      <Form<CancelAppointmentForm>
        initialValues={getInitialValues()}
        onSubmit={handleSubmit}
      >
        {form => {
          return (
            <>
              <div className={Classes.DIALOG_BODY}>
                {!isEmpty(cancelStatuses) && (
                  <CancelStatusWrapper>
                    <FormGroup label="Appointment Status">
                      <SelectInput<Coding>
                        clearable
                        disabled={cancelStatuses.length === 1}
                        field={form.fields.status}
                        items={cancelStatuses}
                        itemsEqual={areCodingsSame}
                        optionRenderer={statusRenderer}
                      />
                    </FormGroup>
                  </CancelStatusWrapper>
                )}
                <Contents>
                  {missedVisit && isMyAvatarMissedVisitReleased && (
                    <div>
                      <Checkbox
                        inline
                        field={form.fields.missedVisit}
                        label="Missed Visit"
                      />
                      {!!form.values.missedVisit && (
                        <ServiceTypeSelect
                          clearable
                          showLabel
                          field={form.fields.missedVisitServiceCode}
                          queryOptions={{
                            missedVisitsOnly: true,
                            episodeOfCareId: appointment.episodeOfCare?.id,
                            locationId: appointment.location.id,
                            patientId: patient?.subject.id,
                            programId: appointment.program?.id,
                            visitDate: LocalDate.fromDate(ZonedDateTime.toDate(appointment.start)),
                          }}
                        />
                      )}
                    </div>
                  )}
                  <FormGroup label="Cancelled Reason">
                    <CancelReasonTextArea field={form.fields.cancelReason} />
                  </FormGroup>
                </Contents>
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button
                    minimal
                    label="Cancel"
                    onClick={onClose}
                  />
                  <Button
                    disabled={!canSubmit}
                    intent={Intent.PRIMARY}
                    label={Text.CancelAppointment}
                    onClick={handleSubmitForm(form)}
                  />
                </div>
              </div>
            </>
          );
        }}
      </Form>
    </Dialog>
  );

  function getInitialValues(): CancelAppointmentForm {
    const status = cancelStatuses.length === 1 ? cancelStatuses[0] : undefined;

    return {
      cancelReason: undefined,
      status,
      missedVisit: false,
      missedVisitServiceCode: undefined,
    };
  }

  function handleSubmitForm(form: FormContent<CancelAppointmentForm>) {
    return async () => {
      await form.submitForm();
    };
  }

  function statusRenderer(item: Coding) {
    const status = getAppointmentStatus(item, labels.Patient);
    return (
      <ListOption
        key={item.code}
        text={status ?? ""}
        onClick={e => e.stopPropagation()}
      />
    );
  }

  function handleSubmit(values: CancelAppointmentForm) {
    if (isEmpty(cancelStatuses) || !values.status) {
      return;
    }

    store.appointments.upsert({
      ...appointment,
      status: values.status,
      cancelReason: values.cancelReason ? {
        text: values.cancelReason,
        codings: [],
      } : undefined,
      missedVisitServiceCode: values.missedVisitServiceCode,
    });
  }
}
