import classnames from "classnames";
import { Classes, DateFormats, Ellipsize, Intent, Popover, Tag, Tooltip, highlightText } from "@remhealth/ui";
import { Identifier, LocalDate, Patient, Reference, isReference, systems } from "@remhealth/apollo";
import { CopyButton, Dot, getPatientGender } from "@remhealth/core";
import { Text } from "~/text";
import { PatientName } from "./patientName";
import { PatientAvatar } from "./patientAvatar";
import {
  AvatarContainer,
  ClientData,
  Container,
  DetailsContainer,
  DobInfo,
  Mrn
} from "./patientBanner.styles";

export interface PatientBannerProps {
  patient: Patient | Reference<Patient>;
  /** @default "normal" */
  scale?: "normal" | "large" | "extra-large" | "small";
  /** Hides MRN and Inactive tag if true. */
  minimal?: boolean;
  fill?: boolean;
  allowCopyMrn?: boolean;
  className?: string;
  interactive?: boolean;
  highlight?: string;
}

const inactiveTag = <Tag minimal>Inactive</Tag>;

export const PatientBanner = (props: PatientBannerProps) => {
  const {
    minimal = false,
    scale = "normal",
    allowCopyMrn = false,
    fill = false,
    className,
    interactive = false,
    patient: patientRef,
    highlight = "",
    ...htmlProps
  } = props;

  const avatarSize = scale === "extra-large" ? 50 : scale === "large" ? 40 : scale === "small" ? 28 : 36;
  const patient = isReference(patientRef) ? patientRef.resource : patientRef;
  const showExtraTags = scale === "extra-large";

  return (
    <Container {...htmlProps} $fill={fill} className={classnames("patient-banner", scale, className)}>
      <AvatarContainer className="avatar">
        <PatientAvatar interactive={interactive} patient={patientRef} size={avatarSize} tooltip={false} />
      </AvatarContainer>
      <DetailsContainer>
        {renderDetails(patient)}
      </DetailsContainer>
    </Container>
  );

  function renderDetails(patient: Patient | undefined) {
    if (!patient) {
      return (
        <Ellipsize className="client-data">
          {highlightText(patientRef.display ?? "", highlight)}
        </Ellipsize>
      );
    }

    const { code: genderCode, display: genderDisplay } = getPatientGender(patient);

    return (
      <>
        <ClientData>
          <Ellipsize maxWidth={400} tooltipContent={getTooltipContent(patient)}>
            <PatientName highlight={highlight} patient={patient} />
          </Ellipsize>
          {!minimal && (
            <>
              {!patient.active && inactiveTag}
              {renderMrn(patient, allowCopyMrn)}
              {showExtraTags && patient.refusedRecording && <Tag minimal intent="purple">{Text.RefusedRecording}</Tag>}
            </>
          )}
        </ClientData>
        <DobInfo>
          <Tooltip content={genderDisplay}>
            {genderCode}
          </Tooltip>
          <Dot />
          {DateFormats.age(LocalDate.toDate(patient.birthDate))}
          <Dot />
          {DateFormats.date(LocalDate.toDate(patient.birthDate))}
        </DobInfo>
      </>
    );
  }

  function getTooltipContent(patient: Patient) {
    const patientId = getPatientId(patient.identifiers);
    return (
      <>
        <ClientData><span><PatientName patient={patient} /></span>{!patient.active && inactiveTag}</ClientData>
        {patientId && <div className={Classes.TEXT_MUTED}>{patientId}</div>}
      </>
    );
  }

  function renderMrn(patient: Patient, allowCopyMrn: boolean) {
    const patientId = getPatientId(patient.identifiers);
    if (allowCopyMrn && patientId) {
      return (
        <Popover
          content={<CopyButton minimal square copyContent={patientId} intent={Intent.PRIMARY} />}
          placement="right"
        >
          <Mrn>{highlightText(patientId, highlight)}</Mrn>
        </Popover>
      );
    }
    return <Mrn>{highlightText(patientId, highlight)}</Mrn>;
  }
};

function getPatientId(identifiers: Identifier[] | undefined) {
  return (identifiers ?? []).find(id => id.system === systems.patientId)?.value ?? "";
}
