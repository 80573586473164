import {
  Approximate,
  DateTimePrecision,
  Duration,
  Identifier,
  Instant,
  LocalDate,
  LocalTime,
  OffsetTime,
  QuestionnaireAnswer,
  QuestionnaireAnswerOption,
  QuestionnaireAnswerValue,
  QuestionnaireElement,
  QuestionnaireValueToken
} from "@remhealth/apollo";
import { DateFormats, FormField, removeUndefined, startsWithAllWords } from "@remhealth/ui";
import { areCodingsSame } from "@remhealth/host";
import { QuestionFlavor, hasLinkedSectionForm, resolveQuestionFlavor } from "./flavors";

export function sortAnswerOptions(answers: QuestionnaireAnswerOption[]) {
  return answers.sort((left, right) => {
    const leftDisplay = getAnswerOptionDisplay(left);
    const rightDisplay = getAnswerOptionDisplay(right);
    if (!leftDisplay || !rightDisplay) {
      return 0;
    }

    return leftDisplay.localeCompare(rightDisplay, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  });
}

export function areSameAnswerOptions(left: QuestionnaireAnswerOption, right: QuestionnaireAnswerOption): boolean {
  if (left.valueInteger !== undefined && right.valueInteger !== undefined && left.valueInteger === right.valueInteger) {
    return true;
  }
  if (left.valueBoolean !== undefined && right.valueBoolean !== undefined && left.valueBoolean === right.valueBoolean) {
    return true;
  }
  if (left.valueString !== undefined && right.valueString !== undefined && left.valueString === right.valueString) {
    return true;
  }
  return false;
}

export function areSameAnswerValues(left: QuestionnaireAnswerValue | undefined, right: QuestionnaireAnswerValue | undefined): boolean {
  if (!left || !right) {
    return left === right;
  }
  if (left.valueCode !== undefined && right.valueCode !== undefined && areCodingsSame(left.valueCode, right.valueCode)) {
    return true;
  }

  const massagedLeft = { identifiers: [], extensions: [], ...left };
  const massagedRight = { identifiers: [], extensions: [], ...right };

  return JSON.stringify(massagedLeft) === JSON.stringify(massagedRight);
}

export function getAnswerOptionDisplay(option: QuestionnaireAnswerOption) {
  if (option.valueBoolean !== undefined) {
    return option.valueBoolean ? "Yes" : "No";
  }

  if (option.valueString !== undefined) {
    return option.valueString.toString();
  }

  if (option.code?.display) {
    return option.code.display;
  }

  if (option.valueInteger !== undefined) {
    return option.valueInteger.toString();
  }

  return "";
}

export function getAnswerValueDisplay(value: QuestionnaireAnswerValue, militaryTime: boolean) {
  if (value.valueString !== undefined) {
    return value.valueString.toString();
  }

  if (value.valueCode?.display) {
    return value.valueCode.display;
  }

  if (value.valueInteger !== undefined) {
    return value.valueInteger.toString();
  }

  if (value.valueDecimal !== undefined) {
    return value.valueDecimal.toString();
  }

  if (value.valueDuration !== undefined) {
    return Duration.humanize(value.valueDuration) || "0 mins";
  }

  if (value.valueBoolean !== undefined) {
    return value.valueBoolean ? "Yes" : "No";
  }

  if (value.valueDate !== undefined) {
    return DateFormats.date(LocalDate.toDate(value.valueDate));
  }

  if (value.valueInstant !== undefined) {
    return DateFormats.dateTime(Instant.toDate(value.valueInstant), militaryTime);
  }

  if (value.valueOffsetTime !== undefined) {
    return DateFormats.dateTime(OffsetTime.toDate(value.valueOffsetTime), militaryTime);
  }

  if (value.valueTime !== undefined) {
    return DateFormats.time(LocalTime.toDate(value.valueTime), militaryTime);
  }

  if (value.valueReference !== undefined) {
    return value.valueReference.display;
  }

  if (value.valueText !== undefined) {
    return value.valueText.plainText;
  }

  if (value.valueApproximate !== undefined) {
    switch (Approximate.getPrecision(value.valueApproximate)) {
      case DateTimePrecision.Full:
        return DateFormats.dateTime(Approximate.toDate(value.valueApproximate), militaryTime);
      default:
        return DateFormats.date(Approximate.toDate(value.valueApproximate));
    }
  }

  return "";
}

export function answerOptionPredicate(query: string, item: QuestionnaireAnswerOption): boolean {
  return startsWithAllWords(getAnswerOptionDisplay(item), query);
}

export function isAnswerOptionValue(option: QuestionnaireAnswerOption, value?: QuestionnaireAnswerValue) {
  if (!value || !answerValueHasValue(value)) {
    return false;
  }

  if (option.code?.code) {
    return !!value.valueCode && option.code.system === value.valueCode.system && option.code.code.toLowerCase() === value.valueCode.code.toLowerCase();
  }

  if (option.valueBoolean !== undefined) {
    return option.valueBoolean === value.valueBoolean;
  }

  if (option.valueString !== undefined) {
    return option.valueString === value.valueString;
  }

  if (option.valueInteger !== undefined) {
    return option.valueInteger === value.valueInteger;
  }

  return false;
}

export function answerOptionToValue(option: QuestionnaireAnswerOption): QuestionnaireAnswerValue {
  return {
    valueString: option.valueString,
    valueInteger: option.valueInteger,
    valueCode: option.code?.code ? option.code : undefined,
    valueBoolean: option.valueBoolean,
    identifiers: option.identifiers,
    extensions: option.extensions,
  };
}

export function answerValueHasValue(value: QuestionnaireAnswerValue): boolean {
  const answer = !!value.valueString?.trim()
    || value.valueInteger !== undefined
    || value.valueDecimal !== undefined
    || value.valueJson !== undefined
    || value.valueBoolean !== undefined
    || value.valueApproximate !== undefined
    || value.valueInstant !== undefined
    || !!value.valueText?.plainText?.trim()
    || value.valueDate
    || value.valueTime
    || value.valueReference
    || value.valueDuration
    || value.valueCode
    || value.valueSignature
    || (!!value.valueGrid && value.valueGrid.length > 0)
    || value.valueDataTable !== undefined;
  return !!answer;
}

export function removeEmptyAnswers(answers: QuestionnaireAnswer[]): QuestionnaireAnswer[] {
  return answers
    .map<QuestionnaireAnswer>(answer => ({
      ...answer,
      values: answer.values.map(value => {
        if (value.valueGrid) {
          return {
            ...value,
            valueGrid: value.valueGrid.map(answerSet => ({
              ...answerSet,
              answers: removeEmptyAnswers(answerSet.answers),
            })),
          };
        }

        return value;
      }),
    }))
    .filter(answer => answer.values.some(answerValueHasValue));
}

export function getQuestionValueFields(field: FormField<QuestionnaireAnswer>) {
  const valueFields = field.fields.values.fields.flatMap(v => Object.values(v.fields).filter(f => !!f)) ?? [];
  return [field.fields.values, ...valueFields];
}

export interface QuestionnaireInitializationContext {
  getValuesByLinkId: (linkId: string) => QuestionnaireAnswerValue[];
  getValuesByIdentifier: (identifier: Identifier, traverse: "parent-or-self" | "parent-only" | "self-only") => QuestionnaireAnswerValue[];
  getValuesByIdentifierSystem: (system: string) => QuestionnaireAnswerValue[];
  getTokenValues: (token: QuestionnaireValueToken) => QuestionnaireAnswerValue[];
}

export function initializeQuestionnaireAnswers(questions: QuestionnaireElement[], context?: QuestionnaireInitializationContext, depth?: number): QuestionnaireAnswer[];
export function initializeQuestionnaireAnswers(questions: QuestionnaireElement[], existingAnswers?: QuestionnaireAnswer[], depth?: number): QuestionnaireAnswer[];
export function initializeQuestionnaireAnswers(questions: QuestionnaireElement[], arg2?: QuestionnaireInitializationContext | QuestionnaireAnswer[], depth = 0): QuestionnaireAnswer[] {
  const answers: QuestionnaireAnswer[] = [];

  const getValuesByLinkId = arg2 && Array.isArray(arg2)
    ? (linkId: string) => arg2.find(a => a.linkId === linkId)?.values ?? []
    : arg2?.getValuesByLinkId ?? (() => []);

  const getValuesByIdentifier = arg2 && !Array.isArray(arg2) ? arg2.getValuesByIdentifier : () => [];
  const getValuesByIdentifierSystem = arg2 && !Array.isArray(arg2) ? arg2.getValuesByIdentifierSystem : () => [];
  const getTokenValues = arg2 && !Array.isArray(arg2) ? arg2.getTokenValues : () => [];

  for (const question of questions) {
    const existingAnswers = getValuesByLinkId(question.linkId);
    if (existingAnswers.length !== 0) {
      answers.push({ linkId: question.linkId, values: existingAnswers.map(a => initializeExistingAnswerValue(question, a, depth)) });
    } else if (!question.initial) {
      answers.push({ linkId: question.linkId, values: getDefaultAnswerValues(question, depth) });
    } else if (!question.initial.dependentValue && answerValueHasValue(question.initial)) {
      answers.push({ linkId: question.linkId, values: [initializeExistingAnswerValue(question, question.initial, depth)] });
    }
  }

  // Handle dependent initial values after we've initialized all literal values
  for (const question of questions) {
    if (question.initial?.dependentValue) {
      if (answers.some(a => a.linkId === question.linkId)) {
        continue;
      }

      const { identifier, identifierSystem, dependentLinkId, valueToken, parentOnly } = question.initial.dependentValue;
      let initialized = false;

      if (identifierSystem) {
        const dependentAnswers = getValuesByIdentifierSystem(identifierSystem);
        if (dependentAnswers.length > 0) {
          answers.push({ linkId: question.linkId, values: dependentAnswers.map(a => initializeExistingAnswerValue(question, a, depth)) });
          initialized = true;
        }
      }

      if (identifier) {
        const dependentAnswers = getValuesByIdentifier(identifier, parentOnly ? "parent-or-self" : "self-only");
        if (dependentAnswers.length > 0) {
          answers.push({ linkId: question.linkId, values: dependentAnswers.map(a => initializeExistingAnswerValue(question, a, depth)) });
          initialized = true;
        }
      }

      if (dependentLinkId) {
        const dependentAnswers = getValuesByLinkId(dependentLinkId);
        if (dependentAnswers.length > 0) {
          answers.push({ linkId: question.linkId, values: dependentAnswers.map(a => initializeExistingAnswerValue(question, a, depth)) });
          initialized = true;
        }
      }

      if (valueToken) {
        const dependentAnswers = getTokenValues(valueToken);
        if (dependentAnswers.length > 0) {
          answers.push({ linkId: question.linkId, values: dependentAnswers.map(a => initializeExistingAnswerValue(question, a, depth)) });
          initialized = true;
        }
      }

      if (!initialized) {
        answers.push({ linkId: question.linkId, values: [] });
      }
    }
  }

  return answers;
}

function getBlankAnswerValue(question: QuestionnaireElement, depth = 0): QuestionnaireAnswerValue | undefined {
  switch (resolveQuestionFlavor(question)) {
    case QuestionFlavor.MultilineText:
      if (depth === 0 && question.form?.resource) {
        return { valueGrid: [{ answers: initializeQuestionnaireAnswers(question.form?.resource.elements, undefined, depth + 1) }] };
      }
      return { valueText: { value: "", plainText: "" } };
    case QuestionFlavor.SinglelineText:
      return { valueString: "" };
    case QuestionFlavor.Integer:
      return { valueInteger: undefined };
    case QuestionFlavor.Decimal:
      return { valueDecimal: undefined };
    case QuestionFlavor.Date:
      return { valueDate: undefined };
    case QuestionFlavor.DateTime:
      return { valueApproximate: undefined };
    case QuestionFlavor.Time:
      return { valueTime: undefined };
    case QuestionFlavor.Duration:
      return { valueDuration: undefined };
    case QuestionFlavor.Checkbox:
      return { valueBoolean: undefined };
    case QuestionFlavor.YesNo:
      return { valueBoolean: undefined };
    case QuestionFlavor.Signature:
      return { valueSignature: undefined };
    case QuestionFlavor.StaffSearch:
    case QuestionFlavor.ServiceSearch:
    case QuestionFlavor.DocumentReference:
      return { valueReference: undefined };
    case QuestionFlavor.UsStateLookup:
    case QuestionFlavor.EhrLookup:
      return { valueCode: undefined };
    case QuestionFlavor.Grid:
      return { valueGrid: [] };
    case QuestionFlavor.TableReport:
      return { valueDataTable: undefined };
    case QuestionFlavor.Ranking:
      return { valueString: undefined };
    case QuestionFlavor.MultiChoice:
    case QuestionFlavor.SingleChoice:
    case QuestionFlavor.Instructions:
    case QuestionFlavor.ParagraphBreak:
    case QuestionFlavor.Calculated:
    case QuestionFlavor.Content:
    case QuestionFlavor.Header:
    case QuestionFlavor.SingleDecision:
    case QuestionFlavor.MultiDecision:
      return undefined;
  }
}

function initializeExistingAnswerValue(question: QuestionnaireElement, value: QuestionnaireAnswerValue, depth = 0): QuestionnaireAnswerValue {
  if (depth === 0 && hasLinkedSectionForm(question)) {
    const answers = initializeQuestionnaireAnswers(question.form.resource?.elements ?? [], value.valueGrid?.flatMap(v => v.answers ?? []), depth + 1);
    return { valueGrid: [{ answers }] };
  }
  return { ...getBlankAnswerValue(question, depth), ...removeUndefined(value) };
}

function getDefaultAnswerValues(question: QuestionnaireElement, depth = 0): QuestionnaireAnswerValue[] {
  const blank = getBlankAnswerValue(question, depth);

  switch (resolveQuestionFlavor(question)) {
    case QuestionFlavor.Ranking:
      return question.answerOptions.map(b => ({ ...blank, valueString: b.valueString }));
    default:
      return blank ? [blank] : [];
  }
}
